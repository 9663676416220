import React from 'react';
import { node } from 'prop-types';
import Theme from './components/Theme/Theme';
import TrafficSource from './components/TrafficSource/TrafficSource';

const propTypes = {
  element: node.isRequired,
};

const Root = ({ element }) => (
  <Theme>
    {element}
    <TrafficSource />
  </Theme>
);

Root.propTypes = propTypes;

export default Root;
