import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Link from '../../Link/Link';
import languages from './languages.json';

const DropdownButton = styled.button(
  css({
    appearance: 'none',
    bg: 'transparent',
    border: 'none',
    color: ['text', 'highlight'],
    cursor: 'pointer',
    fontSize: 'xs',
    fontWeight: ['bold', 'normal'],
    textAlign: 'left',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  }),
);

const HideText = styled.span(
  css({
    display: ['none', 'inline'],
  }),
);

const dropdownStyles = {
  root: {
    bg: 'white',
    minWidth: '160px',
    borderRadius: 0,
    border: '1px',
    borderColor: 'blackAlpha.300',
    boxShadow: 'none',
  },
};

const dropdownItemStyles = {
  root: {
    textTransform: 'uppercase',
    fontSize: 'xs',
    py: '3px',
    my: 1,
    color: 'gray.800',
    ':hover': {
      textDecoration: 'none',
    },
  },
};

const Language = () => {
  const dropdown = useDropdownState();

  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps, caret) => (
          <DropdownButton type="button" {...toggleProps}>
            Eng<HideText>lish</HideText>
            {caret}
          </DropdownButton>
        )}
      />
      <Dropdown.Menu classes={dropdownStyles} aria-label="Dropdown menu">
        {dropdown.visible &&
          languages.map(({ label, url }) => (
            <Dropdown.Item
              key={url}
              as={Link}
              to={url}
              classes={dropdownItemStyles}
            >
              {label}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Language;
