/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import {
  MenuBar,
  MenuItem,
  useMenuState,
  useMenuBarState,
  Menu as BaseMenu,
  MenuButton as BaseMenuButton,
} from 'reakit/Menu';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { MdArrowDropDown } from 'react-icons/md';
import BaseNav from '@bit/medicalwebexperts.mwe-ui.nav';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Link from '../../../Link/Link';
import NavigationLink from './NavigationLink';

const MenuButton = styled.button(
  ({ 'aria-expanded': visible, firstLevel, isScrolled }) =>
    css({
      transition: 'ease-in-out 0.12s',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      color: visible && firstLevel ? 'primary.main' : 'white',
      bg: visible && !firstLevel ? 'whiteAlpha.300' : 'secondary.main',
      whiteSpace: 'nowrap',
      px: () => {
        if (firstLevel) {
          return isScrolled ? 2 : 3;
        }
        return 2;
      },
      py: firstLevel ? 3 : 1,
      fontSize: () => {
        if (firstLevel) {
          return isScrolled ? 'xs' : 'sm';
        }
        return 'sm';
      },
      border: 'none',
      ':focus': {
        outline: 0,
      },
      ':hover': {
        bg: 'whiteAlpha.300',
        textDecoration: 'none',
      },
    }),
);

const Menu = styled(BaseMenu)(
  css({
    bg: 'secondary.main',
  }),
);

const Nav = styled(BaseNav)(
  css({
    bg: 'secondary.main',
    div: {
      borderLeft: '1px',
      borderColor: 'white',
      minWidth: '160px',
      zIndex: 5,
      'button, a': {
        py: 1,
        px: 2,
        textTransform: 'none',
        borderBottom: '1px',
        borderBottomColor: 'whiteAlpha.200',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        svg: {
          ml: 4,
        },
      },
    },
  }),
);

const useStyles = (firstLevel) => ({
  icon: {
    root: { transform: `rotate(${firstLevel ? 0 : -90}deg)` },
  },
});

const NavMenu = React.forwardRef(
  ({ firstLevel, isScrolled, label, icon, depth, items, hideParent }, ref) => {
    const menu = useMenuState();
    const styles = useStyles(firstLevel);

    const handleHide = () => {
      hideParent();
      menu.hide();
    };

    return (
      <>
        <BaseMenuButton ref={ref} {...menu}>
          {(menuProps) => (
            <MenuButton
              {...menuProps}
              firstLevel={firstLevel}
              isScrolled={isScrolled}
            >
              {label}
              {icon && <Icon size={6} name={icon} />}
              <Icon size={6} as={MdArrowDropDown} classes={styles.icon} />
            </MenuButton>
          )}
        </BaseMenuButton>
        {menu.visible && (
          <Menu {...menu} aria-label={label}>
            {items.map((subItem, index) => (
              <NavItem
                key={`${subItem.label}${index}`}
                depth={depth + 1}
                onHide={handleHide}
                item={subItem}
              />
            ))}
          </Menu>
        )}
      </>
    );
  },
);

const NavItem = React.memo(
  React.forwardRef(
    (
      { item, isScrolled = false, depth = 0, onHide = () => null, ...props },
      ref,
    ) => {
      const firstLevel = depth === 0;

      const { label, url, items, icon } = item;
      const hasItems = Array.isArray(items) && items.length;

      return hasItems ? (
        <NavMenu
          ref={ref}
          items={items}
          firstLevel={firstLevel}
          isScrolled={isScrolled}
          depth={depth}
          label={label}
          icon={icon}
          hideParent={onHide}
        />
      ) : (
        <NavigationLink
          as={Link}
          to={url}
          onClick={onHide}
          isFirstLevel={firstLevel}
          isScrolled={isScrolled}
          {...props}
          id={`item-${depth}-${label}`}
        >
          {label}
          {icon && <Icon size={6} name={icon} />}
        </NavigationLink>
      );
    },
  ),
  (prev, next) => prev.isScrolled === next.isScrolled,
);

const NavigationDesktop = ({ navigation, isScrolled }) => {
  const menu = useMenuBarState();

  return (
    <MenuBar {...menu}>
      {(props) => (
        <Nav {...props}>
          {navigation.map((item) => (
            <MenuItem
              {...menu}
              key={`first-${item.label}`}
              id={`first-${item.label}`}
              item={item}
              isScrolled={isScrolled}
              as={NavItem}
            />
          ))}
          <MenuItem {...menu} id="item-contactus">
            {(menuItemProps) => (
              <NavigationLink
                {...menuItemProps}
                as={Link}
                to="/getstarted/contactus/"
                contact
              >
                Contact Us
              </NavigationLink>
            )}
          </MenuItem>
        </Nav>
      )}
    </MenuBar>
  );
};

export default memo(NavigationDesktop, (prev, next) => {
  return prev.isScrolled === next.isScrolled;
});
