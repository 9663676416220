import React from 'react';
import { node } from 'prop-types';
import { Provider } from 'reakit';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import theme from '../../themes/default';

const propTypes = {
  children: node.isRequired,
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Normalize />
    <Provider>{children}</Provider>
  </ThemeProvider>
);

Theme.propTypes = propTypes;

export default Theme;
