import React from 'react';

const LgtbFlag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="21"
    viewBox="0 0 31 21"
  >
    <g id="Group_630" data-name="Group 630" transform="translate(-1308 -516)">
      <rect
        id="Rectangle_1375"
        data-name="Rectangle 1375"
        width="31"
        height="3"
        transform="translate(1308 516)"
        fill="#ff0401"
      />
      <rect
        id="Rectangle_1378"
        data-name="Rectangle 1378"
        width="31"
        height="3"
        transform="translate(1308 522)"
        fill="#ffff06"
      />
      <rect
        id="Rectangle_1380"
        data-name="Rectangle 1380"
        width="31"
        height="3"
        transform="translate(1308 528)"
        fill="#04d6ce"
      />
      <rect
        id="Rectangle_1376"
        data-name="Rectangle 1376"
        width="31"
        height="3"
        transform="translate(1308 519)"
        fill="#ff9702"
      />
      <rect
        id="Rectangle_1377"
        data-name="Rectangle 1377"
        width="31"
        height="3"
        transform="translate(1308 525)"
        fill="#029501"
      />
      <rect
        id="Rectangle_1379"
        data-name="Rectangle 1379"
        width="31"
        height="3"
        transform="translate(1308 531)"
        fill="#4800a1"
      />
      <rect
        id="Rectangle_1381"
        data-name="Rectangle 1381"
        width="31"
        height="3"
        transform="translate(1308 534)"
        fill="#9d0099"
      />
    </g>
  </svg>
);

export default LgtbFlag;
