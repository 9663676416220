/* eslint-disable react/prop-types */
import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { MdKeyboardArrowRight } from 'react-icons/md';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import BaseLink from '../../../../Link/Link';
import { usePushMenu } from './Context';

const Link = styled(BaseLink)(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px',
    borderBottomColor: 'rgba(255,255,255,.21)',
    bg: 'transparent',
    width: '100%',
    py: '10px',
    pl: 5,
    pr: 5,
    color: '#dbdbdb',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 'md',
    '&.active': {
      bg: 'gray.600',
      color: 'primary.main',
    },
  }),
);

const MenuButton = styled.button(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
    borderBottom: '1px',
    borderBottomColor: 'rgba(255,255,255,.21)',
    bg: 'transparent',
    width: '100%',
    py: '10px',
    pl: 5,
    pr: 5,
    color: '#dbdbdb',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 'md',
  }),
);

const Item = ({ onClick, node }) => {
  const { openSubMenu } = usePushMenu();
  const hasChildren = node.items && node.items.length > 0;

  const handleClick = () => {
    openSubMenu(node);
  };

  return hasChildren ? (
    <MenuButton onClick={handleClick}>
      {node.label}
      {node.icon && <Icon size={6} name={node.icon} />}
      <Icon as={MdKeyboardArrowRight} color="white" />
    </MenuButton>
  ) : (
    <Link to={node.url} onClick={onClick} activeClassName="active">
      {node.label}
      {node.icon && <Icon size={6} name={node.icon} />}
    </Link>
  );
};

export default Item;
