/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { MdKeyboardArrowLeft, MdMenu } from 'react-icons/md';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import { usePushMenu } from './Context';
import Item from './Item';

const Toolbar = styled(Flex)(
  css({
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: 'primary.main',
    p: 0,
  }),
);

const PusherBase = styled.div(
  css({
    height: '100%',
    width: '100%',
    transition: 'all 0.5s',
    bg: 'secondary.dark',

    '&::after': {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 0,
      height: 0,
      content: "''",
      opacity: 0,
      transition: 'opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s',
    },
  }),
);

const Pusher = styled(PusherBase)({
  position: 'relative',
  left: 0,
  height: '100%',
  width: '100%',
  opacity: 1,
  transition: 'opacity 0.3s',
});

const Level = styled(PusherBase)({
  '::after': {
    zIndex: -1,
  },
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

const ToolbarTitle = styled(Typography)({
  fontFamily: 'heading',
  my: 4,
  color: 'white',
  fontSize: '11px',
  textTransform: 'uppercase',
  flex: '1',
  textAlign: 'center',
});

const Menu = ({ onClose }) => {
  const { nodes, visibleMenus, removeLastMenu } = usePushMenu();

  return (
    <Pusher>
      {visibleMenus.map((menuNode = {}, level) => (
        <Level key={level}>
          <Toolbar>
            <Button
              variant="text"
              variantColor="white"
              onClick={removeLastMenu}
              startIcon={
                <Icon as={MdKeyboardArrowLeft} size={6} color="primary.main" />
              }
              classes={{
                startIcon: { bg: 'black', borderRadius: 'full' },
                root: {
                  bg: 'transparent',
                  textTransform: 'capitalize',
                  visibility: level === 0 ? 'hidden' : undefined,
                },
              }}
            >
              Back
            </Button>
            <ToolbarTitle>
              {level === 0 ? nodes.header : menuNode.label}
            </ToolbarTitle>
            <IconButton
              type="button"
              onClick={onClose}
              variant="contained"
              variantColor="primary"
              corner="square"
              size="lg"
            >
              <Icon as={MdMenu} size={6} />
            </IconButton>
          </Toolbar>

          {menuNode.items.map((node, index) => (
            <Item key={index} onClick={onClose} node={node} />
          ))}
        </Level>
      ))}
    </Pusher>
  );
};

export default Menu;
