import preset, {
  breakpoints,
} from '@bit/medicalwebexperts.mwe-ui.themes.preset';
import LgtbFlag from '../components/Icons/LgtbFlag';

export default {
  ...preset,
  ...breakpoints({
    sm: '30em',
    md: '48em',
    lg: '65em',
    xl: '80em',
  }),
  fonts: {
    ...preset.fonts,
    body:
      '"PT Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading: '"Tinos", "Times New Roman", Times, serif',
  },
  typography: {
    h1: {
      fontFamily: 'heading',
      fontSize: '4xl',
      fontWeight: 'light',
      lineHeight: 'shorter',
      letterSpacing: 'tight',
      mb: 3,
    },
    h2: {
      fontFamily: 'heading',
      fontSize: '3xl',
      fontWeight: 'light',
      lineHeight: 'shorter',
      mb: 3,
    },
    h3: {
      fontFamily: 'heading',
      fontSize: '2xl',
      fontWeight: 'light',
      lineHeight: 'base',
      position: 'relative',
      mb: 3,
    },
    h4: {
      fontFamily: 'heading',
      fontSize: 'xl',
      fontWeight: 'normal',
      lineHeight: 'base',
      mb: 3,
    },
    h5: {
      fontFamily: 'heading',
      fontSize: 'lg',
      fontWeight: 'normal',
      lineHeight: 'base',
      mb: 3,
    },
    h6: {
      fontFamily: 'heading',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'base',
      mb: 3,
    },
    subtitle1: {
      textTransform: 'uppercase',
      fontFamily: 'heading',
      fontSize: 'lg',
      fontWeight: 'light',
      position: 'relative',
      pb: 1,
      mb: 3,
      '::before, ::after': {
        content: '" "',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: '3px',
      },
      '::before': {
        width: '100%',
        bg: 'gray.300',
      },
      '::after': {
        width: 10,
        bg: 'primary.main',
      },
    },
    subtitle2: {
      fontFamily: 'body',
      fontSize: 'lg',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    body1: {
      fontFamily: 'body',
      fontSize: 'md',
      fontWeight: 'normal',
      lineHeight: 'tall',
    },
    body2: {
      fontFamily: 'body',
      fontSize: 'sm',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    caption: {
      fontFamily: 'body',
      fontSize: 'xs',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    overline: {
      fontFamily: 'body',
      fontSize: 'sm',
      fontWeight: 'normal',
      lineHeight: 'base',
    },
    inherit: {},
  },
  colors: {
    ...preset.colors,
    text: '#333333',
    highlight: '#0b5cab',
    accent: '#1DA0B1',
    primary: {
      main: '#f7941e',
      dark: '#a95f06',
    },
    secondary: {
      main: '#404040',
      dark: '#333333',
    },
  },
  sizes: {
    ...preset.sizes,
    container: {
      ...preset.sizes.container,
      xl: '1170px',
    },
  },
  icons: {
    lgtb: LgtbFlag,
  },
};
