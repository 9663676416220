import React, { forwardRef } from 'react';
import { bool, node, string } from 'prop-types';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import BaseLink from '@bit/medicalwebexperts.mwe-ui.link';

const propTypes = {
  children: node.isRequired,
  to: string.isRequired,
  activeClassName: string,
  partiallyActive: bool,
};

const defaultProps = {
  activeClassName: undefined,
  partiallyActive: undefined,
};

const Link = forwardRef(
  ({ children, to, activeClassName, partiallyActive, ...props }, ref) => {
    const {
      allSitePage: { edges: routes },
    } = useStaticQuery(
      graphql`
        query {
          allSitePage {
            edges {
              node {
                path
              }
            }
          }
        }
      `,
    );
    const internal = routes.find(({ node: e }) => e.path === to);

    if (internal) {
      return (
        <BaseLink
          ref={ref}
          to={to}
          color="highlight"
          {...props}
          as={GatsbyLink}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
        >
          {children}
        </BaseLink>
      );
    }

    return (
      <BaseLink ref={ref} color="highlight" {...props} href={to} rel="noopener">
        {children}
      </BaseLink>
    );
  },
);

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
