import React from 'react';
import { node } from 'prop-types';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const propTypes = {
  children: node.isRequired,
};

const Layout = ({ children }) => (
  <>
    <Header />
    <main>{children}</main>
    <Footer />
  </>
);

Layout.propTypes = propTypes;

export default Layout;
