// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-egg-donation-egg-donor-application-index-js": () => import("./../../../src/pages/egg-donation/egg-donor-application/index.js" /* webpackChunkName: "component---src-pages-egg-donation-egg-donor-application-index-js" */),
  "component---src-pages-egg-donation-egg-donor-database-index-js": () => import("./../../../src/pages/egg-donation/egg-donor-database/index.js" /* webpackChunkName: "component---src-pages-egg-donation-egg-donor-database-index-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-getstarted-contactus-js": () => import("./../../../src/pages/getstarted/contactus.js" /* webpackChunkName: "component---src-pages-getstarted-contactus-js" */),
  "component---src-pages-getstarted-second-opinion-contactus-1-js": () => import("./../../../src/pages/getstarted/second-opinion/contactus1.js" /* webpackChunkName: "component---src-pages-getstarted-second-opinion-contactus-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-internal-page-js": () => import("./../../../src/templates/internal-page.js" /* webpackChunkName: "component---src-templates-internal-page-js" */)
}

