/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { MdMenu } from 'react-icons/md';
import Sidebar, {
  useSidebarState,
} from '@bit/medicalwebexperts.mwe-ui.sidebar';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import PushMenu from './PushMenu/PushMenu';

const useStyles = (isScrolled) => ({
  iconButton: {
    root: {
      position: isScrolled ? 'relative' : 'absolute',
      right: isScrolled ? 'auto' : [0, '40px'],
      top: isScrolled ? 0 : '-60px',
      ml: isScrolled ? 2 : 0,
      bg: isScrolled ? 'primary.main' : 'transparent',
      borderRadius: isScrolled ? 0 : 'full',
      size: '45px',
    },
  },
  icon: {
    root: {
      color: isScrolled ? 'white' : 'black',
    },
  },
  sidebar: {
    root: { border: 'none', bg: 'secondary.dark' },
    backdrop: { zIndex: '2000' },
  },
});

const NavigationMobile = ({ isScrolled, navigation }) => {
  const sidebar = useSidebarState({ animated: true });
  const styles = useStyles(isScrolled);

  return (
    <>
      <Sidebar.Disclosure {...sidebar}>
        {(props) => (
          <IconButton variant="text" {...props} classes={styles.iconButton}>
            <Icon as={MdMenu} size={6} classes={styles.icon} />
          </IconButton>
        )}
      </Sidebar.Disclosure>
      {sidebar.visible && (
        <Sidebar
          state={sidebar}
          size="full"
          ariaLabel="Navigation"
          classes={styles.sidebar}
        >
          <PushMenu
            nodes={{
              header: 'CENTER FOR HUMAN REPRODUCTION',
              items: navigation,
            }}
            onClose={sidebar.hide}
          />
        </Sidebar>
      )}
    </>
  );
};

export default memo(NavigationMobile, (prev, next) => {
  return prev.isScrolled === next.isScrolled;
});
