import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { MdClose } from 'react-icons/md';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import BaseLink from '../../Link/Link';

const Wrapper = styled(Flex)(
  css({
    alignItems: 'center',
    bg: 'primary.main',
    justifyContent: 'center',
    p: 5,
  }),
);

const Link = styled(BaseLink)(
  css({
    color: 'white',
    display: 'inline-block',
    ml: 1,
    textDecoration: 'underline',
  }),
);

const Alert = () => {
  const [showAlert, setShowAlert] = useState(true);
  const handleAlertClose = () => {
    setShowAlert(false);
    window.sessionStorage.setItem('hour-alert-closed', true);
  };

  useEffect(() => {
    const isClose = window.sessionStorage.getItem('hour-alert-closed');

    if (isClose) {
      setShowAlert(false);
    }
  }, []);

  return !showAlert ? null : (
    <Wrapper>
      <Typography variant="subtitle2">
        <strong>
          CHR is open, and medical travel to NYC is possible.
          <Link to="/fertility/covid-19-and-ivf-chrs-always-up-to-date-guide/">
            Learn more.
          </Link>
        </strong>
      </Typography>
      <IconButton
        size="sm"
        color="white"
        ml={2}
        onClick={handleAlertClose}
        aria-label="Close alert"
      >
        <MdClose size={24} />
      </IconButton>
    </Wrapper>
  );
};

export default Alert;
