import React, { memo } from 'react';
import { func, shape } from 'prop-types';
import Provider from './Context';
import Menu from './Menu';

const propTypes = {
  nodes: shape({}).isRequired,
  onClose: func.isRequired,
};

const PushMenu = ({ nodes, onClose }) => {
  return (
    <Provider nodes={nodes}>
      <Menu onClose={onClose} />
    </Provider>
  );
};

PushMenu.propTypes = propTypes;

export default memo(PushMenu, () => true);
