/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import Root from './src/Root';
import Page from './src/Page';
import 'typeface-tinos';
import 'typeface-pt-sans';
import 'pure-react-carousel/dist/react-carousel.es.css';

export const wrapRootElement = Root;

export const wrapPageElement = Page;
