/* eslint-disable react/prop-types */
import React, { useState, createContext, useContext, useEffect } from 'react';

const Context = createContext({
  nodes: {},
  visibleMenus: [],
  removeLastMenu: () => undefined,
  openSubMenu: () => undefined,
});

export const usePushMenu = () => useContext(Context);

const Provider = ({ children, nodes }) => {
  const [visibleMenus, setVisibleMenus] = useState([nodes]);

  const addMenu = (menu) => {
    setVisibleMenus((prevMenus) => [...prevMenus, menu]);
  };

  const removeLastMenu = () =>
    setVisibleMenus((prevMenus) => {
      const clonedMenus = Array.from(prevMenus);
      clonedMenus.pop();

      return clonedMenus;
    });

  const openSubMenu = (node) => {
    if (!(node.items.length < 1)) {
      addMenu(node);
    }
  };

  const context = {
    nodes,
    visibleMenus,
    removeLastMenu,
    openSubMenu,
  };

  useEffect(() => {
    const currentPagePath = window ? window.location.pathname : null;

    const findPath = (node, prevNode = nodes, prevNodes = []) => {
      const haveItems = node.items && node.items.length > 0;

      if (haveItems) {
        node.items.forEach((el) => {
          const newPrevNodes = [...prevNodes, node];

          findPath(el, node, newPrevNodes);
        });
      } else if (node.url === currentPagePath) {
        setVisibleMenus((prevMenus) => {
          const existItem = prevMenus.find((el) => el.label === prevNode.label);

          return !existItem ? [...prevMenus, ...prevNodes] : prevMenus;
        });
      }
    };

    if (currentPagePath !== '/') {
      nodes.items.forEach((node) => {
        findPath(node);
      });
    }
  }, [nodes]);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Provider;
