import css from '@styled-system/css';
import styled from '@emotion/styled';
import Nav from '@bit/medicalwebexperts.mwe-ui.nav';

const NavigationLink = styled(Nav.Link)(
  ({ isFirstLevel = true, isScrolled }) =>
    css({
      fontWeight: 'normal',
      textTransform: 'uppercase',
      color: 'white',
      whiteSpace: 'nowrap',
      transition: 'ease-in-out 0.12s',
      px: () => {
        if (isFirstLevel) {
          return isScrolled ? 2 : 3;
        }
        return 2;
      },
      py: isFirstLevel ? 3 : 1,
      fontSize: () => {
        if (isFirstLevel) {
          return isScrolled ? 'xs' : 'sm';
        }
        return 'sm';
      },
      border: 'none',
      ':focus': {
        outline: 0,
      },
      ':hover': {
        bg: 'whiteAlpha.300',
        textDecoration: 'none',
      },
    }),
  ({ contact }) =>
    contact &&
    css({
      bg: 'primary.main',
      ':hover': {
        bg: 'primary.main',
        opacity: '.9',
      },
    }),
);

export default NavigationLink;
