import React, { useEffect, useState } from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { useMedia, useWindowScroll } from 'react-use';
import { useStaticQuery, graphql, Link as InternalLink } from 'gatsby';
import Nav from '@bit/medicalwebexperts.mwe-ui.nav';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Navbar from '@bit/medicalwebexperts.mwe-ui.navbar';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import { useBreakpoints } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import Link from '../../../Link/Link';
import NavigationDesktop from './NavigationDesktop';
import NavigationMobile from './NavigationMobile';
import NavigationLink from './NavigationLink';
import LogoCollapse from '../../../../images/chr-logo-collapse.png';
import PhoneIcon from '../../../../images/icon-phone.png';

const IconLink = styled(NavigationLink)(
  css({
    display: 'flex',
    alignItems: 'center',
    py: 1,
  }),
);

const IconImage = styled(Image)(
  css({
    bg: 'primary.main',
    borderRadius: 'sm',
    mr: 1,
    size: 8,
  }),
);

const Wrapper = styled.div(({ isScrolled }) =>
  css({
    top: 0,
    left: 0,
    right: 0,
    position: isScrolled ? 'fixed' : 'static',
    height: isScrolled ? '50px' : 'auto',
    zIndex: 'tooltip',
    bg: 'secondary.main',
    transition: 'ease-in-out 0.12s',
  }),
);

const MainNavbar = styled(Navbar)(({ isScrolled }) =>
  css({
    justifyContent: isScrolled
      ? 'space-between'
      : ['flex-end', null, null, 'flex-start'],
    position: 'relative',
  }),
);

const CollapseLogo = styled(Image)(({ isScrolled }) =>
  css({
    display: isScrolled ? 'block' : 'none',
    position: 'relative',
    top: 1,
  }),
);

const CollapsePhone = styled.div(({ isScrolled }) =>
  css({
    display: isScrolled ? ['none', null, null, 'flex'] : 'none',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  }),
);

const PhoneLink = styled(Link)(
  css({
    color: 'white',
    ':hover': {
      textDecoration: 'none',
      color: 'primary.main',
      cursor: 'pointer',
    },
  }),
);

const Navigation = () => {
  const { y } = useWindowScroll();
  const breakpoints = useBreakpoints();
  const isMobile = useMedia(`(max-width: ${breakpoints.lg})`);
  const [showMobileMenu, setShowMobileMenu] = useState(true);
  const {
    settingsJson: { navigation },
  } = useStaticQuery(
    graphql`
      query {
        settingsJson {
          navigation {
            label
            url
            items {
              label
              url
              icon
              items {
                label
                url
                items {
                  label
                  url
                }
              }
            }
          }
        }
      }
    `,
  );
  const isScrolled = y > 10;

  useEffect(() => {
    setShowMobileMenu(isMobile);
  }, [isMobile]);

  return (
    <Wrapper isScrolled={isScrolled}>
      <Container>
        <MainNavbar expand="lg" isScrolled={isScrolled}>
          <InternalLink to="/">
            <CollapseLogo src={LogoCollapse} isScrolled={isScrolled} />
          </InternalLink>

          {/* 1024 */}
          <Flex justifyContent="flex-end">
            <Nav alignItems="center">
              <Flex display={['none', 'flex', null, 'none']}>
                <Nav.Item>
                  <IconLink as={Link} to="tel:12129944400">
                    <IconImage src={PhoneIcon} /> Line 1
                  </IconLink>
                </Nav.Item>
                <Nav.Item>
                  <IconLink as={Link} to="tel:12129335700">
                    <IconImage src={PhoneIcon} /> Line 2
                  </IconLink>
                </Nav.Item>
                <Nav.Item>
                  <NavigationLink as={Link} to="/getstarted/contactus/" contact>
                    Contact Us
                  </NavigationLink>
                </Nav.Item>
              </Flex>
              <Nav.Item>
                {showMobileMenu ? (
                  <NavigationMobile
                    navigation={navigation}
                    isScrolled={isScrolled}
                  />
                ) : (
                  <Navbar.Collapse>
                    <NavigationDesktop
                      navigation={navigation}
                      isScrolled={isScrolled}
                    />
                  </Navbar.Collapse>
                )}
              </Nav.Item>
            </Nav>
          </Flex>
          <CollapsePhone isScrolled={isScrolled}>
            <PhoneLink to="tel:12129944400">
              <strong>(212) 994-4400</strong>
            </PhoneLink>
            <PhoneLink to="tel:12129335700">(212) 933-5700</PhoneLink>
          </CollapsePhone>
        </MainNavbar>
      </Container>
    </Wrapper>
  );
};

export default Navigation;
