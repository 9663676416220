import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Nav from '@bit/medicalwebexperts.mwe-ui.nav';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Link from '../../Link/Link';
import Floating from './Floating';
import SocialLinks from './SocialLinks';
import ChrFooterLogo from '../../../images/chr-logo-footer.png';
import MweLogo from '../../../images/mwe-logo.png';
import EhlaLogo from '../../../images/ehla-logo.png';
import AsfLogo from '../../../images/asf-logo.jpg';
import SartLogo from '../../../images/sart-logo.jpg';
import FrmLogo from '../../../images/frm-logo.jpg';

const MainFooter = styled.footer(
  css({
    position: 'relative',
    pb: [24, null, 0],
  }),
);

const NavigationWrapper = styled.div(
  css({
    bg: 'gray.200',
    borderTop: '1px',
    borderColor: 'blackAlpha.200',
  }),
);

const Navigation = styled(Nav)(
  css({
    py: 2,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: ['column', null, null, 'row'],
    alignItems: 'center',
  }),
);

const NavigationLink = styled(Nav.Link)(
  css({
    py: [1, null, null, 0],
    px: 2,
    fontSize: 'xs',
    textTransform: 'uppercase',
  }),
);

const LegalWrapper = styled.div(
  css({
    bg: 'secondary.main',
    py: 5,
  }),
);

const LegalLinkWrapper = styled(Nav.Item)(
  css({
    borderRight: '1px',
    borderColor: 'rgba(255,255,255,.2)',
  }),
);

const LegalLink = styled(Nav.Link)(
  css({
    py: 0,
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'sm',
  }),
);

const Slogan = styled(Typography)(
  css({
    color: 'primary.main',
    fontFamily: 'heading',
    fontSize: 'lg',
    mb: '2',
  }),
);

const WhiteLink = styled(Link)(
  css({
    color: 'white',
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

const LogosWrapper = styled.div(
  css({
    bg: 'white',
    py: 2,
  }),
);

const Footer = () => (
  <MainFooter>
    {/* Links */}
    <NavigationWrapper>
      <Container>
        <Navigation>
          <Nav.Item>
            <NavigationLink as={Link} to="/infertilityedu/causes/">
              Causes of Infertility
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/services/testing-diagnostics/hsg/">
              Testing &amp; Diagnostics
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/services/infertility-treatments/">
              Treatments
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/egg-donation/">
              Egg Donation Program
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/fertility/">
              Fertility Updates
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/getstarted/contactus/">
              Contact Us
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/tips/">
              Useful Tips
            </NavigationLink>
          </Nav.Item>
          <Nav.Item>
            <NavigationLink as={Link} to="/professionals/">
              For Health Professionals
            </NavigationLink>
          </Nav.Item>
        </Navigation>
      </Container>
    </NavigationWrapper>

    {/* Legal */}
    <LegalWrapper>
      <Container>
        <Flex
          justifyContent="space-between"
          flexDirection={['column', null, 'row']}
        >
          <Flex.Box textAlign={['center', null, 'left']}>
            <Slogan>
              Over 30 Years Leading in Infertility Care <br />
              Serving Patients Worldwide
            </Slogan>
            <Image
              src={ChrFooterLogo}
              mx={['auto', null, 0]}
              alt="CHR"
              responsive
            />
          </Flex.Box>
          <Flex.Box textAlign={['center', null, 'right']}>
            {/* Street Address */}
            <Typography variant="body2" mb={1}>
              <WhiteLink
                to="https://www.google.com/maps/dir/''/Center+for+Human+Reproduction/@40.7698174,-74.0363568,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c258ec625517a1:0x11c93f190d7f617b!2m2!1d-73.9663169!2d40.7698386"
                target="_blank"
              >
                21 E 69th St <br />
                New York, NY 10021
              </WhiteLink>
            </Typography>
            {/* Phone Numbers */}
            <Typography color="white" variant="body2" mb={1}>
              Questions? Call{' '}
              <WhiteLink
                to="tel:12129944400"
                onclick="ga('send', 'event', 'Calls', 'Click');"
              >
                <strong>(212) 994-4400</strong>
              </WhiteLink>
              <WhiteLink to="tel:12129335700"> (212) 933-5700</WhiteLink>
            </Typography>
            {/* Legal and Legal Links */}
            <Typography color="white" variant="body2" mb={1}>
              Copyright © 2020 Center for Human Reproduction. All Rights
              Reserved.
            </Typography>
            <Nav justifyContent={['center', null, 'flex-end']} mb={1}>
              <LegalLinkWrapper>
                <LegalLink as={Link} to="/privacy/">
                  Privacy Policy
                </LegalLink>
              </LegalLinkWrapper>
              <LegalLink as={Link} to="/terms/">
                Terms & Conditions
              </LegalLink>
            </Nav>
            {/* Social Links */}
            <SocialLinks />
            {/* Develope and Seo */}
            <Typography mb={1}>
              <Link to="https://www.medicalwebexperts.com/" target="_blank">
                <Image src={MweLogo} alt="Medical Web Experts" />
              </Link>
            </Typography>
            <Typography color="white" variant="body2">
              Medical SEO by MD
              <WhiteLink
                to="https://www.mdmarketingdigital.com/"
                target="_blank"
              >
                {' '}
                <strong>Marketing Digital</strong>
              </WhiteLink>
            </Typography>
          </Flex.Box>
        </Flex>
      </Container>
    </LegalWrapper>

    {/* Logos */}
    <LogosWrapper>
      <Container>
        <Flex alignItems="center">
          <Flex.Box width="25%">
            <Image responsive src={EhlaLogo} mx="auto" alt="Ehla" />
          </Flex.Box>
          <Flex.Box width="25%">
            <Image responsive src={AsfLogo} mx="auto" alt="Asf" />
          </Flex.Box>
          <Flex.Box width="25%">
            <Image responsive src={SartLogo} mx="auto" alt="Sart" />
          </Flex.Box>
          <Flex.Box width="25%">
            <Image responsive src={FrmLogo} mx="auto" alt="Frm" />
          </Flex.Box>
        </Flex>
      </Container>
    </LogosWrapper>

    {/* Econsultation Floating Button */}
    <Floating />
  </MainFooter>
);

export default Footer;
