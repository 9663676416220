import React, { useState } from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Link from '../../Link/Link';
import ContactIcon from '../../../images/icon-contact.png';
import CrossIcon from '../../../images/icon-cross.png';
import CalendarIcon from '../../../images/calendar-icon.png';
import PhoneIcon from '../../../images/icon-phone.png';
import EmailIcon from '../../../images/icon-email.png';

const Wrapper = styled(Flex)(
  css({
    width: 'auto',
    position: 'fixed',
    right: ['0', '55px'],
    bottom: 0,
    left: ['0', 'auto'],
    bg: 'secondary.main',
    borderRadius: '8px 8px 0 0',
    zIndex: 'modal',
    py: 4,
    px: 6,
    justifyContent: 'space-between',
    maxWidth: ['100%', '375px'],
  }),
);

const ContactButton = styled.button(
  css({
    display: ['flex', 'none'],
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: 'transparent',
    border: '1px',
    borderColor: 'transparent',
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflow: 'visible',
    textDecoration: 'none',
    userSelect: 'none',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  }),
);

const EconsultationLink = styled.a(
  css({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  }),
);

const Icon = styled.div(
  css({
    borderRadius: 'full',
    size: '50px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'white',
    p: 2,
    mr: [2, 4],
  }),
);

const IconConsultation = styled(Icon)(
  css({
    bg: 'accent',
  }),
);

const IconContact = styled(Icon)(
  css({
    bg: 'primary.main',
  }),
);

const Hightlight = styled.strong(
  css({
    display: 'block',
    color: 'primary.main',
  }),
);

const Overlay = styled.div(
  css({
    display: ['block', 'none'],
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    bg: 'blackAlpha.900',
    zIndex: 'overlay',
  }),
);

const ContactInfo = styled.div(({ active }) =>
  css({
    position: 'fixed',
    bottom: active ? '100px' : '-160px',
    right: 0,
    left: 0,
    px: 6,
    transition: 'ease-in-out 0.12s',
    zIndex: '1350',
  }),
);

const InfoLink = styled(Flex)(
  css({
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
    mt: 3,
  }),
);

const InfoIcon = styled.div(
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    size: '40px',
    bg: 'primary.main',
    borderRadius: 'full',
    ml: 3,
  }),
);

const FloatingFooter = () => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      {open && <Overlay />}
      <Wrapper>
        <Flex.Box>
          <EconsultationLink
            href="https://e-consultation.centerforhumanreprod.com/"
            target="_blank"
            rel="noopener"
          >
            <IconConsultation>
              <Image src={CalendarIcon} alt="Calendar" responsive />
            </IconConsultation>
            <Typography color="white" lineHeight="1.25">
              Request an <Hightlight>e-Consultation</Hightlight>
            </Typography>
          </EconsultationLink>
        </Flex.Box>
        <ContactButton type="button" onClick={handleToggle}>
          <IconContact>
            <Image
              src={open ? CrossIcon : ContactIcon}
              alt="Contact Us"
              responsive
            />
          </IconContact>
          <Typography color="white" lineHeight="1.25">
            Questions? <Hightlight>Contact Us</Hightlight>
          </Typography>
        </ContactButton>
        {/* Hidden Contact Div  */}
      </Wrapper>
      <ContactInfo active={open}>
        <InfoLink
          as="a"
          href="tel:12129335700"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Flex.Box>(212) 933-5700</Flex.Box>
          <Flex.Box>
            <InfoIcon>
              <Image src={PhoneIcon} alt="Phone" responsive />
            </InfoIcon>
          </Flex.Box>
        </InfoLink>
        <InfoLink
          as="a"
          href="tel:12129944400"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Flex.Box>(212) 994-4400</Flex.Box>
          <Flex.Box>
            <InfoIcon>
              <Image src={PhoneIcon} alt="Phone" responsive />
            </InfoIcon>
          </Flex.Box>
        </InfoLink>
        <InfoLink
          as={Link}
          to="/getstarted/contactus"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Flex.Box>Contact Form</Flex.Box>
          <Flex.Box>
            <InfoIcon>
              <Image src={EmailIcon} alt="Email" responsive />
            </InfoIcon>
          </Flex.Box>
        </InfoLink>
      </ContactInfo>
    </>
  );
};

export default FloatingFooter;
