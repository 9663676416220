import React from 'react';
import { Link as InternalLink } from 'gatsby';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { MdSearch } from 'react-icons/md';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Container from '@bit/medicalwebexperts.mwe-ui.container';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import Link from '../../Link/Link';
import Navigation from './Navigation/Navigation';
import Alert from './Alert';
import Language from './Language';
import Logo from '../../../images/chr-logo.png';
import LogoMobile from '../../../images/logo-chr-mobile.png';
import IconMap from '../../../images/icon-map.jpg';

const HeaderStyled = styled.header(
  css({
    bg: 'white',
    boxShadow: ['0 3px 3px rgba(0, 0, 0, 0.15)', 'none'],
    position: 'relative',
    zIndex: 'tooltip',
  }),
);

const TopHeader = styled(Flex)(
  css({
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: '1px',
    borderColor: 'gray.100',
    py: 2,
    mb: 2,
  }),
);
const TopHeaderLink = styled(Link)(
  css({
    fontSize: 'xs',
    textTransform: ['none', 'uppercase'],
    whiteSpace: 'nowrap',
    color: ['text', 'highlight'],
  }),
);

const FormControl = styled(Form.Control)(
  css({
    height: 5,
    borderRadius: 0,
    pl: 2,
  }),
);

const searchButtonStyles = {
  root: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    p: 0,
  },
};

const PhoneLink = styled(Link)(
  css({
    color: 'black',
    fontSize: '2xl',
    fontWeight: 'bold',
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

const PhoneLinkSmall = styled(PhoneLink)(
  css({
    fontSize: 'xl',
  }),
);

const PhoneDivider = styled.div(
  css({
    fontSize: 'lg',
    px: 2,
  }),
);
const LogoWrapper = styled.div(
  css({
    width: ['250px', '450px', '511px', '670px'],
  }),
);
const DesktopLogo = styled(Image)(
  css({
    display: ['none', 'block'],
  }),
);

const MobileLogo = styled(Image)(
  css({
    display: ['block', 'none'],
  }),
);

const Serving = styled(Typography)(
  css({
    fontSize: 'lg',
    fontFamily: 'heading',
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: 'short',
  }),
);

const ServingLocation = styled(Serving)(
  css({
    fontSize: 'sm',
    fontWeight: 'normal',
    fontStyle: 'normal',
  }),
);

const Header = () => {
  return (
    <HeaderStyled>
      <Alert />
      <Container pb={2}>
        {/* Top Access */}
        <TopHeader>
          <Flex.Box order={[2, 1]} mx={[2, 0]}>
            <TopHeaderLink to="/portal-login/" target="_blank">
              Portal Login
            </TopHeaderLink>
          </Flex.Box>
          <Flex.Box order={[1, 2]}>
            <Form
              action="/search/"
              onSubmit={() => {
                window.fbq('init', '459761310869040');
                window.fbq('track', 'Lead');
              }}
            >
              <Form.Group mb={0} mx={[0, 3]}>
                <Form.ControlGroup size="sm">
                  <FormControl name="s" placeholder="Search this site" />
                  <Form.AddonElementRight
                    as="button"
                    type="submit"
                    classes={searchButtonStyles}
                  >
                    <MdSearch size={18} />
                  </Form.AddonElementRight>
                </Form.ControlGroup>
              </Form.Group>
            </Form>
          </Flex.Box>
          <Flex.Box order={3}>
            <Language />
          </Flex.Box>
        </TopHeader>

        {/* Institutional Header */}
        <Flex justifyContent="space-between">
          {/* Logo */}
          <Flex.Box>
            <InternalLink to="/">
              <LogoWrapper>
                <DesktopLogo src={Logo} responsive alt="CHR logo" />
                <MobileLogo src={LogoMobile} responsive alt="CHR logo" />
              </LogoWrapper>
            </InternalLink>
          </Flex.Box>
          <Flex.Box display={['none', null, null, 'block']}>
            {/* Phone Numbers */}
            <Flex alignItems="center" justifyContent="flex-end">
              <PhoneLink to="tel:2129944400">(212) 994-4400</PhoneLink>
              <PhoneDivider>-</PhoneDivider>
              <PhoneLinkSmall to="tel:2129335700">
                (212) 933-5700
              </PhoneLinkSmall>
            </Flex>
            {/* Location and Map */}
            <Flex alignItems="center" justifyContent="flex-end" mt={2}>
              <Flex.Box mr={2}>
                <Serving>
                  <Link to="/services/int/">Serving Patients Worldwide</Link>
                </Serving>
                <ServingLocation variant="body2">
                  <Link
                    to="https://www.google.com/maps/dir/''/Center+for+Human+Reproduction/@40.7698174,-74.0363568,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c258ec625517a1:0x11c93f190d7f617b!2m2!1d-73.9663169!2d40.7698386"
                    target="_blank"
                  >
                    21 E 69th St, New York, NY 10021
                  </Link>
                </ServingLocation>
              </Flex.Box>
              <Flex.Box>
                <Image src={IconMap} alt="Map" />
              </Flex.Box>
            </Flex>
          </Flex.Box>
        </Flex>
      </Container>

      {/* Main Nav */}
      <Navigation />
    </HeaderStyled>
  );
};

export default Header;
