import React from 'react';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { ImTwitter, ImFacebook, ImLinkedin2, ImYoutube } from 'react-icons/im';
import { AiFillInstagram } from 'react-icons/ai';
import { MdPlace } from 'react-icons/md';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';

const Wrapper = styled(Flex)(
  css({
    my: 2,
    alignItems: 'center',
    justifyContent: ['center', null, 'flex-end'],
  }),
);

const colors = {
  twitter: '#2ea7de',
  facebook: '#39589b',
  place: '#d95139',
  linkedin: '#127fc0',
  youtube: '#ca3030',
  instagram: '#d62d7c',
};

const Link = styled.a(({ network }) =>
  css({
    textDecoration: 'none',
    color: 'secondary.main',
    bg: 'gray.600',
    size: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'full',
    ml: 2,
    ':hover': {
      bg: colors[network],
    },
  }),
);

const SocialLinks = () => (
  <Wrapper as="p">
    <Link
      href="https://twitter.com/infertilityNY"
      target="_blank"
      network="twitter"
      aria-label="Twitter"
      rel="noopener"
    >
      <Icon as={ImTwitter} size={18} />
    </Link>

    <Link
      href="https://www.facebook.com/thechr"
      target="_blank"
      network="facebook"
      aria-label="Facebook"
      rel="noopener"
    >
      <Icon as={ImFacebook} size={18} />
    </Link>

    <Link
      href="https://www.google.com/maps/place/Center+for+Human+Reproduction/@40.7698386,-73.9663169,15z/data=!4m5!3m4!1s0x0:0x11c93f190d7f617b!8m2!3d40.7698386!4d-73.9663169"
      target="_blank"
      network="place"
      aria-label="Place"
      rel="noopener"
    >
      <Icon as={MdPlace} size={18} />
    </Link>

    <Link
      href="https://www.instagram.com/centerforhumanreproduction/"
      target="_blank"
      network="instagram"
      aria-label="Instagram"
      rel="noopener"
    >
      <Icon as={AiFillInstagram} size={18} />
    </Link>

    <Link
      href="https://www.linkedin.com/company/center-for-human-reproduction"
      target="_blank"
      network="linkedin"
      aria-label="Linkedin"
      rel="noopener"
    >
      <Icon as={ImLinkedin2} size={18} />
    </Link>

    <Link
      href="https://www.youtube.com/user/CenterForHumanReprod"
      target="_blank"
      network="youtube"
      aria-label="Youtube"
      rel="noopener"
    >
      <Icon as={ImYoutube} size={18} />
    </Link>
  </Wrapper>
);

export default SocialLinks;
